var DESCRIPTORS = require(21);
var definePropertyModule = require(57);
var createPropertyDescriptor = require(19);

module.exports = DESCRIPTORS ? function (object, key, value) {
  return definePropertyModule.f(object, key, createPropertyDescriptor(1, value));
} : function (object, key, value) {
  object[key] = value;
  return object;
};
