'use strict';
var IteratorPrototype = require(47).IteratorPrototype;
var create = require(55);
var createPropertyDescriptor = require(19);
var setToStringTag = require(76);
var Iterators = require(48);

var returnThis = function () { return this; };

module.exports = function (IteratorConstructor, NAME, next) {
  var TO_STRING_TAG = NAME + ' Iterator';
  IteratorConstructor.prototype = create(IteratorPrototype, { next: createPropertyDescriptor(1, next) });
  setToStringTag(IteratorConstructor, TO_STRING_TAG, false, true);
  Iterators[TO_STRING_TAG] = returnThis;
  return IteratorConstructor;
};
